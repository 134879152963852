import api, { UserDevice } from "../../../../api"
import { useState } from "react"
import Button from "../../../../components/button"
import uuid from "../../../../utils/uuid"
import Icon from "../../../../components/icon"
import Modal from "../../../../components/modal"
import ConfigureDevice from "./ConfigureDevice"

export default (props:{
    devices: UserDevice[],
    onDevicesChange: (devices:UserDevice[]) => void
}) => {

    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const deleteDevice = (uuid:string) => {
        setLoading(true)
        handleResponse(api.user.devices.remove(uuid))
    }

    const handleResponse = (p:Promise<any>) => {
        return p.then((response) => {
            props.onDevicesChange(response.data || [])
        })
        .catch((error) => {
            console.error(error)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return <>
        <div>
            <span>Mobile devices ({props.devices.length} configured)</span>
            <ul>
                {props.devices.map((d, i) => <li key={i} style={{fontStyle: "italic"}}>
                    <Icon type="mobile"/> {d.name}
                    <Icon type="trash" onPress={() => deleteDevice(d.uuid)}/>
                </li>)}
            </ul>
        </div>
        
        <Button text="Add mobile device" onClick={() => setShowModal(true)} style={{}}/>

        <Modal visible={!!showModal} onPressOut={() => {
            setShowModal(false)
        }}>
            <div style={{
                padding: "20px",
                backgroundColor: "white",
                borderRadius: "5px",
                width: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {showModal ? <ConfigureDevice 
                    onSuccess={devices => {
                        setShowModal(false)
                        props.onDevicesChange(devices)
                    }}
                    onCancelPressed={() => {
                        setShowModal(false)
                    }}
                /> : null}
            </div>
        </Modal>
    </>
}