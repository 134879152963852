import { useEffect, useRef, useState } from "react"

let qrScanner: any

export default (props:{
    onQrScanned:(data:string) => void
    onError?:(error:string) => void
}) => {
    const ref = useRef<HTMLVideoElement>(null)
    const [start, setStart] = useState(true)

    useEffect(() => {
        if(ref.current) {
            try {
                // @ts-expect-error
                qrScanner = new QrScanner(
                    ref.current,
                    (result:{data:string}) => {
                        props.onQrScanned(result.data)
                    },
                    { /* your options or returnDetailedScanResult: true if you're not specifying any other options */ },
                );
                console.log(qrScanner)
            } catch (err:any) {
                console.error(err)
                props.onError && props.onError(err.message)
            }
        }
    }, [ref.current])

    useEffect(() => {
        if(!qrScanner) return
        if(start) {
            navigator.mediaDevices.getUserMedia({
                audio: false,
                video: true
            }).then((stream) => {
               return qrScanner.start()
            }).catch((err) => {
                console.error(err)
                props.onError && props.onError(err.message)
            })
        } else {
            qrScanner.stop();
        }
    }, [start])

    return <video ref={ref} id="qr_scanner_video" style={{width: "100%", height: "auto"}} />
}