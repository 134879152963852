export const BASE_URL = "/api"

export const getBaseUrl = () => {
    const URLparams = new URLSearchParams(window.location.search)
    const baseUrl = URLparams.get('baseUrl')
    const url = baseUrl || BASE_URL
    if(!url.startsWith("http")) return `${window.location.protocol}//${window.location.host}${url}`
    return url
}

type APIResponse<T> = {
    meta: {
        time: number,
        code: number,
    }
    data?: T,
    error?: any
}

const APIFetch = async <T>(url:string, options?: RequestInit) => {
    const response = await fetch(`${getBaseUrl()}${url}`, options)
    const data = await response.json() as APIResponse<T>
    return data
}

type User = {
    nId: string,
    sTotpSecret?: string,
    jDevices?: string
}

type LoginResponse = {
    mfa?: string,
    user: User | null
}

export type UserDevice = {
    uuid: string,
    name: string,
    createdAt: number
}

export const auth = {
    login: async (creds:{
        user: string,
        password: string
    }) => {
        return APIFetch<LoginResponse>("/auth/login", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(creds)
        })
    },
    mfa: async ({
        mfa,
        code
    }:{
        mfa: string,
        code: string
    }) => {
        return APIFetch<LoginResponse>(`/auth/mfa?mfa=${mfa}&code=${code}`)
    }
}

export const user = {
    me: async () => {
        return APIFetch<User>("/user")
    },
    totp: {
        configure: async () => {
            return APIFetch<{totpUrl: string}>("/user/totp", {
                method: "PUT"
            })
        },
        verify: async (code:string) => {
            return APIFetch<string>("/user/totp", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({code})
            })
        }
    },
    devices: {
        list: async () => {
            return APIFetch<UserDevice[]>("/user/devices")
        },
        create: async () => {
            return APIFetch<string>("/user/devices", {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
            })
        },
        add: async (name:string, uuid:string, pushToken:string) => {
            return APIFetch<string>(`/device?uuid=${uuid}`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({name, pushToken})
            })
        },
        remove: async (uuid:string) => {
            return APIFetch<UserDevice[]>("/user/devices", {
                method: "DELETE",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({uuid})
            })
        }
    }
}

export default {
    auth,
    user
}