import { useEffect, useState } from "react"
import Button from "../../../../components/button"
import api, { UserDevice, getBaseUrl } from "../../../../api"
import Input from "../../../../components/input"
import qrcode from "../../../../utils/qrcode"
import Loader from "../../../../components/loader"

export default (props:{
    onSuccess:(devices:UserDevice[]) => void
    onCancelPressed:() => void
}) => {
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState<{
        uuid: string | null,
        qrb64: string,
        error: string | null
    }>({error: null, qrb64: "", uuid: null})

    const addDevice = () => {
        setLoading(true)
        api.user.devices.create()
        .then((response) => {
            const path = getBaseUrl()
            const uuid = response.data
            if(!path || !uuid) throw new Error("Invalid response")
            console.log({path, uuid})
            const url = `${path}/device?uuid=${uuid}`
            qrcode(url)
            .then((b64) => {
                setState({...state, qrb64: b64, uuid})
            })
        })
        .catch((error) => {
            console.error(error)
            setState({...state, error: error.message})
        })
        .finally(() => {
            // setLoading(false)
        })
    }

    useEffect(() => {
        if(!state.uuid) {
            addDevice()
        }
    }, [])

    useEffect(() => {
        if(state.uuid) {
            const interval = setInterval(() => {
                api.user.devices.list()
                .then((response) => {
                    const devices = response.data || []
                    if(devices.find(d => d.uuid === state.uuid)) {
                        props.onSuccess(devices)
                        clearInterval(interval)
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
            }, 5000)
            return () => clearInterval(interval)
        }
    }, [state.uuid])

    const cancel = () => {
        setState({error: null, qrb64: "", uuid: null})
        props.onCancelPressed()
    }

    return <>
        <img src={state.qrb64} alt="QRCode" />
        <span style={{marginTop: 10, marginBottom: 10}}>
            Scan this QR Code with the SolveFit PT App, then wait for the device to be automatically added.
        </span>
        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <Button text="Cancel" variant="outlined" onClick={() => cancel()} style={{}}/>
            {/* <Button loading={loading} text="Next" onClick={() => setState({error: null, qrb64: "", uuid: null})} style={{}}/> */}
            {loading ? <Loader /> : null}
        </div>
    </>
}