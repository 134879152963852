import { useState } from "react"
import Button from "../../../components/button"
import Input from "../../../components/input"
import api from "../../../api"

export default ({
    loginToken
}:{
    loginToken:string
}) => {
    const [loading, setLoading] = useState(false)
    const [creds, setCreds] = useState({
        user: "",
        password: "",
        mfa: "",
        code: ""
    })

    const [step, setStep] = useState<"form" | "mfa">("form")

    const authenticate = () => {
        // Create fake post form
        const form = document.createElement("form")
        form.method = "POST"
        form.action = "/user_login.php"
        form.style.display = "none"
        const fields = {
            nFactor: "1",
            bJavascript: "1",
            UserName: creds.user,
            Password: creds.password,
            Submit: "Login",
            sToken: loginToken || ""
        }
        for (const key in fields) {
            const input = document.createElement("input")
            input.name = key
            input.value = fields[key as keyof typeof fields]
            form.appendChild(input)
        }
        document.body.appendChild(form)
        form.submit()
    }

    const login = () => {
        setLoading(true)
        api.auth.login(creds)
        .then((response) => {
            if(response.data?.mfa) {
                setStep("mfa")
                setCreds({...creds, mfa: response.data.mfa})
            } else if (response.data?.user) {
                console.log("User logged in", response.data?.user.nId)
                authenticate()
            }
        })
        .catch((error) => {
            console.error(error)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const loginMfa = () => {
        setLoading(true)
        api.auth.mfa({
            mfa: creds.mfa,
            code: creds.code
        })
        .then((response) => {
            if(response.data?.user) {
                console.log("User logged in", response.data?.user.nId)
                authenticate()
            } else {
                console.error("MFA failed")
            }
        })
        .catch((error) => {
            console.error(error)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const LoginView = <>
        <Input
            // label="User"
            onChange={(e) => setCreds({...creds, user: e.target.value})}
            placeholder="Username"
            style={{marginBottom: "10px"}}
            value={creds.user}
            />

        <Input
            // label="Password"
            type="password"
            onChange={(e) => setCreds({...creds, password: e.target.value})}
            placeholder="Password"
            style={{marginBottom: "10px"}}
            value={creds.password}
            />
        
        <Button
            text="Log In"
            loading={loading}
            onClick={login} />
    </>

    const MfaView = <>
        <Input
            // label="MFA"
            onChange={(e) => setCreds({...creds, code: e.target.value})}
            placeholder="MFA Code"
            style={{marginBottom: "10px"}}
            value={creds.code}
            />

        <Button
            text="Log In"
            loading={loading}
            onClick={loginMfa}
            style={{marginBottom: "10px"}}
             />
        
        <Button
            text="Back"
            style={{backgroundColor: "silver"}}
            onClick={() => setStep("form")}
            />
    </>


    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            // backgroundColor: "red",
            justifyContent:"center",
            alignItems: "center",
        }}>
            <h1>Please Login</h1>
            {step === "form" ? LoginView : null}
            {step === "mfa" ? MfaView : null}            
        </div>
    )
}