import { useState } from "react"
import QrScanner from "../../components/QrScanner"
import Loader from "../../components/loader"
import api from "../../api"
import postMessageToNativeApp from "./postMessageToNativeApp"
import { finished } from "stream"

export default () => {
    const [error, setError] = useState<string | null>(null)
    const [message, setMessage] = useState<string | null>(null)
    const [status, setStatus] = useState({loading:false, finished:false})

    const onQrScanned = (url:string) => {
        setStatus({loading:true, finished:false})
        setMessage("SCANNED:"+url)

        const nurl = new URL(url)
        const uuid = nurl.searchParams.get("uuid")

        if(!uuid) return setError("Invalid QR code")

        postMessageToNativeApp(url, "add_device")
        .then((response) => {
            console.log(response)
            setMessage(response)
        })
        .catch((error) => {
            console.error(error)
            setError(error.message || error)
        })
        .finally(() => {
            setStatus({loading:false, finished:true})
        })
    }

    return <div>
        <h1>SolveFit PT App</h1>
        {message}
        <span style={{}}>{error}</span>
        {status.loading ? <Loader /> :
            status.finished ? null :
            <QrScanner onQrScanned={onQrScanned} onError={setError}/>}
    </div>
}