import { useEffect } from "react"
import Login from "../../views/forms/auth/Login"

export default ({
    loginToken
}:{
    loginToken:string
}) => {
    useEffect(() => {
        /* Expire cookie if it exists */
        document.cookie = `X-PT-AUTH=; expires=${new Date().toUTCString()}; path=/;`;
    })
    return <Login loginToken={loginToken}/>
}