const QRCode = require("./qrcode")

type Output = 'svg' | 'pdf' | 'eps' | 'svgpath' | 'png'

/**
 * Generate a QRCode from a string
 * @param data The data to encode
 * @returns The image of the QRCode as a base64 string
 */
export default (data:string) => {
    return new Promise<string>((resolve, reject) => {
        const element = document.createElement("div")
        new QRCode(element, {
            text: data,
            width: 256,
            height: 256,
            colorDark : "#000000",
            colorLight : "#ffffff",
            correctLevel : QRCode.CorrectLevel.Q
        })
        const img = element.querySelector("img")!
        if(!img) throw new Error("Cannot generate QRCode")
        img.setAttribute("type", "qrcode")
        img.onload = () => resolve(img.src)
        img.onerror = () => reject(new Error("Error generating QRCode"))
    })
}