import uuid from "../../utils/uuid";

type ResRej = {
    resolve:(s:string) => void,
    reject:(s:string) => void
}

declare global {
    interface Window {
        ReactNativeWebView?: {
            postMessage: (message: string) => void;
        };
        RNCommsMap: Map<string, ResRej>;
    }
}

window.RNCommsMap = new Map<string, ResRej>();

export default (message:string, type:string) => {
    const rid = uuid()
    return new Promise<string>((resolve, reject) => {
        if(!window.ReactNativeWebView?.postMessage) return reject("ReactNativeWebView not found")
        window.RNCommsMap.set(rid, {resolve, reject})
        window.ReactNativeWebView!.postMessage(`rncomm:${rid}:${type}:${message}`)
    })
}