import Loader from "../loader"

export default (props:{
    text?: string,
    children?: React.ReactNode
    onClick: () => void
    loading?: boolean
    style?: React.CSSProperties,
    color?: string,
    variant?: "contained" | "outlined"
    disabled?: boolean
}) => {
    let variant = props.variant
    if(!props.variant) variant = "contained"
    let color = props.color
    if(!props.color) color = props.disabled ? "grey" : "rgb(102, 170, 221)"
    return <div
        onClick={() => {
            if(props.loading || props.disabled) return
            props.onClick()
        }}
        style={{
            backgroundColor: variant === "outlined" ? "white" : color,
            borderColor: color,
            borderWidth: 1,
            borderStyle: "solid",
            color: variant === "outlined" ? color : "white",
            minWidth: "100px",
            minHeight: "30px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10px 0 10px",
            cursor: props.loading || props.disabled ? "initial" : "pointer",
            ...(props.style || {})
        }}>
            {props.loading ? <Loader color="grey" /> :
            (props.text || props.children)}
        </div>
}