type Props = {
    children: React.ReactNode
    visible: boolean
    onPressOut: () => void
}

export default (props:Props) => {
    return <div
        onClick={e => {
            if(e.target === e.currentTarget) {
                props.onPressOut()
            }
        }}
        style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1000,
            display: props.visible ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center"
        }}>
        {props.children}
    </div>
}