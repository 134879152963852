import { useEffect } from "react"
import api from "../../api"
import UserForm from "../../views/forms/user"

export default () => {

  useEffect(() => {
    api.user.me() 
  })
  return <div className="formlayer">
    <fieldset>
      <legend>Multi Factor Authentication</legend>
      {/* <div>
        <label>App Features:</label>
        <div className="button-box bb-multi xlg" style={{minHeight:"20px"}}>
            <table>
            <colgroup><col width="6%"/><col width="27.33%"/><col width="6%"/><col width="27.33%"/><col width="6%"/><col width="27.33%"/></colgroup>
            <tbody>
              <tr>
                <td>
                  <input type="checkbox" className="Data" id="bDoScatter" name="bDoScatter" readOnly={false} checked={false} />
                </td><td>Scatter Ch</td>
                <td><input type="checkbox" className="Data" id="bDoSms" name="bDoSms" readOnly={false} checked={false} /></td><td>SMS/Text</td>
              </tr>
            </tbody>
          </table>

        </div>
        <span className="btnHelp" href="#" onclick="showHelp('hlpFeatures', event);">?</span>
      </div> */}
      <UserForm />

    </fieldset>
  </div>
}