export default ({
    type,
    onPress,
    style = {}

}:{
    type: string
    style?: React.CSSProperties
    onPress?: () => void
}) => {
    return <i
        onClick={onPress}
        className={`fa fa-solid fa-${type}`}
        style={{
            cursor: onPress ? "pointer" : "default",
            ...style
        }}></i>
}