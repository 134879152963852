import { useEffect, useState } from "react"
import Button from "../../../components/button"
import api, { UserDevice } from "../../../api"
import Loader from "../../../components/loader"
import Modal from "../../../components/modal"
import qrcode from "../../../utils/qrcode"
import ConfigureTOTP from "./totp/ConfigureTOTP"
import uuid from "../../../utils/uuid"
import ManageDevices from "./devices/ManageDevices"
import ConfigureDevice from "./devices/ConfigureDevice"

type State = {
    totpConfigured: boolean
    mobileDevices: UserDevice[]
    qrCodeB64: string | null
}

export default () => {

    const [loading, setLoading] = useState<"all" | "auth" | "devices" | null>("all")
    const [showModal, setShowModal] = useState<"totp" | "devices" | false>(false)
    const [state, setState] = useState<State>({
        totpConfigured: false,
        mobileDevices: [],
        qrCodeB64: null
    })

    const configureTotp = () => {
        setLoading("auth")
        api.user.totp.configure()
        .then((response) => {
            if(!response.data?.totpUrl) throw new Error("No TOTP URL")
            return qrcode(response.data.totpUrl)
        })
        .then((b64) => {
            setState({
                ...state,
                qrCodeB64: b64
            })
            setShowModal("totp")
        })
        .catch((error) => {
            console.error(error)
        })
        .finally(() => {
            setLoading(null)
        })
    }

    useEffect(() => {
        Promise.all([api.user.me(), api.user.devices.list()])
        .then(([u, d]) => {
            setState({
                totpConfigured: !!u.data?.sTotpSecret,
                mobileDevices: d.data || [],
                qrCodeB64: null
            })
        })
        .finally(() => {
            setLoading(null)
        })
    }, [])

    return loading === "all" ? <Loader /> : <>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
            <span>Authenticator App {state.totpConfigured ? "" : "not"} configured {state.totpConfigured ? "✅" : "❔"}</span>
            <Button
                loading={loading === "auth"}
                text={`${state.totpConfigured ? "Rec" : "C"}onfigure Authenticator`}
                onClick={() => configureTotp()} style={{}}/>
        </div>

        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
            <ManageDevices
                devices={state.mobileDevices}
                onDevicesChange={(devices) => {
                    setState({
                        ...state,
                        mobileDevices: devices
                    })
                }} />
        </div>

        <Modal visible={!!showModal} onPressOut={() => {
            setShowModal(false)
        }}>
            <div style={{
                padding: "20px",
                backgroundColor: "white",
                borderRadius: "5px",
                width: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {showModal === "totp" ? <ConfigureTOTP
                    b64={state.qrCodeB64 || ""}
                    onCancelPressed={() => {
                        setShowModal(false)
                        setState({
                            ...state,
                            totpConfigured: false,
                        })
                    }}
                    onSuccess={() => {
                        setShowModal(false)
                        setState({
                            ...state,
                            qrCodeB64: null,
                            totpConfigured: true,
                        })
                    }}
                    /> : null}
                
                {showModal === "devices" ? <ConfigureDevice 
                    onSuccess={() => {
                        
                    }}
                    onCancelPressed={() => {
                        setShowModal(false)
                    }}
                /> : null}
            </div>
        </Modal>
    </>
}