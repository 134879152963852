import { useState } from "react"
import styles from "./styles"
import Icon from "../icon"

export default (props:{
    type?: "text" | "password",
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    label?: string,
    placeholder?: string,
    style?: React.CSSProperties
    inputStyle?: React.CSSProperties
    value?: string
}) => {

    const [showPassword, setShowPassword] = useState(false)
    const inputStyle:React.CSSProperties = {
        borderRadius: "5px",
        borderWidth: "1px solid",
        backgroundColor: "white",
        outline: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...(props.inputStyle || {})
    }
    return <div style={{
        display: "flex",
        ...(props.style || {})
    }}>
        {props.label && <label>{props.label}</label>}
        <div style={inputStyle}>
            <input
                type={showPassword ? "text" : (props.type || "text")}
                style={{
                    ...styles,
                    ...inputStyle
                }}
                onChange={e => props.onChange && props.onChange(e)}
                placeholder={props.placeholder}
                value={props.value}
                />
            <Icon
                type={showPassword ? "eye" : "eye-slash"}
                style={{
                    opacity: props.type === "password" ? 1 : 0,
                    // width: "40px",
                }}
                onPress={() => setShowPassword(!showPassword)}
                />
        </div>
    </div>
}