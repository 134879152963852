import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Auth from './root/Auth';

import './index.css';
import UserForm from './root/UserForm';
import App from './root/App';


window.addEventListener("DOMContentLoaded", function (e) {

  let root = document.querySelector("#app_root") as HTMLElement
  if(root) {
    return ReactDOM.createRoot(root).render(
      // <React.StrictMode> 
        <App />
      // </React.StrictMode>
    );
  }

  root = document.querySelectorAll("#frmLogin")[0] as HTMLElement
  if(root) {
    const div = document.createElement("div")
    const loginToken = (document.querySelector("input[type=hidden][name=sToken]") as HTMLInputElement)?.value
    root.replaceWith(div)
    return ReactDOM.createRoot(div).render(
      <React.StrictMode>
        <Auth loginToken={loginToken}/>
      </React.StrictMode>
    );
  }

  root = document.querySelectorAll("#frmUser")[0] as HTMLElement
  if(root) {
    const div = document.createElement("div")
    root.appendChild(div)
    return ReactDOM.createRoot(div).render(
      <React.StrictMode>
        <UserForm />
      </React.StrictMode>
    );
  }

  const link = document.createElement("link")
  link.rel = "stylesheet"
  link.href = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css"
  document.head.appendChild(link)
});



// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
