import { useState } from "react"
import Button from "../../../../components/button"
import api from "../../../../api"
import Input from "../../../../components/input"

export default (props:{
    b64:string,
    onSuccess:() => void
    onCancelPressed:() => void
}) => {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState<{
        step: "qr" | "verify",
        code: string,
        error: string | null
    }>({step: "qr", error: null, code: ""})

    const verify = (code:string) => {
        setState({...state, error: null})
        setLoading(true)
        api.user.totp.verify(code)
        .then(d => {
            if(d.meta.code !== 200) throw new Error("Invalid code")
            props.onSuccess()
        })
        .catch((error) => {
            console.error(error)
            setState({...state, error: error.message})
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const cancel = () => {
        setState({step: "qr", error: null, code: ""})
        props.onCancelPressed()
    }

    if(state.step === "qr") return <>
        <img src={props.b64} alt="QRCode" />
        <span style={{marginTop: 10, marginBottom: 10}}>Scan this QR Code with your authenticator App, then click next.</span>
        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <Button loading={loading} text="Cancel" variant="outlined" onClick={() => cancel()} style={{}}/>
            <Button loading={loading} text="Next" onClick={() => setState({step: "verify", error: null, code: ""})} style={{}}/>
        </div>
    </>
    else if(state.step === "verify") return <>
        <span style={{marginTop: 10, marginBottom: 10}}>Enter the code given by your authenticator App, then click next.</span>
        <Input type="text" style={{width: "90%", borderWidth: 1, marginBottom: 10, border: "1px solid"}} value={state.code} onChange={(e) => setState({...state, code: e.target.value})} />
        
        {state.error && <span style={{color: "red", marginBottom: 10}}>{state.error}</span>}
        
        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <Button loading={loading} text="Back" variant="outlined" onClick={() => setState({step: "qr", error: null, code: ""})} style={{}}/>
            <Button disabled={!state.code.length} loading={loading} text="Next" onClick={() => verify(state.code)} style={{}}/>
        </div>
    </>
    return null
}